import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          // d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
          d="M 25 62 c -3.2805 -2.9646 -3.5964 -3.7179 -3.5964 -9.4041 c 0 -5.9049 0.2187 -6.4638 4.1553 -9.8415 c 2.3085 -1.9683 4.0581 -4.4712 4.0581 -5.9049 c 0 -1.9683 0.6561 -2.3085 4.374 -2.3085 c 3.1833 0 4.374 0.4374 4.374 1.6524 c 0 0.8748 -0.972 1.6524 -2.187 1.6524 c -1.215 0 -2.187 0.5589 -2.187 1.0935 c 0 0.6561 1.4337 1.0935 3.1512 1.5495 c 1.6524 0 2 2 5 1 c 1.8711 1.8711 4.1553 -1.7496 4.1553 -6.2451 c 0 -2.9646 -0.7533 -4.5927 -3.1833 -6.561 c -3.0618 -2.7459 -5.4675 -2.8431 -10.7163 -0.7533 c -1.7496 0.6561 -1.9683 0.3402 -1.6524 -1.8711 c 0.3402 -2.4057 0.972 -2.6244 6.561 -2.9646 c 5.5647 -0.3402 6.561 0 9.8415 2.9646 c 3.2805 2.9646 3.5964 3.7179 3.5964 9.4041 c 0 5.9049 -0.2187 6.4638 -4.0581 9.8415 c -2.4057 1.9683 -4.1553 4.4712 -4.1553 5.9049 c 0 1.9683 -0.6561 2.3085 -4.374 2.3085 c -3.1833 0 -4.374 -0.4374 -4.374 -1.6524 c 0 -0.8748 0.972 -1.6524 2.187 -1.6524 c 1.215 0 2.187 -0.4374 2.187 -1.0935 c 0 -0.5589 -1.4337 -1.0935 -4.0148 -3.6302 c -1.6524 0 -3.5964 -0.5589 -4.1553 -1.0935 c -1.8711 -1.8711 -4.1553 1.7496 -4.1553 6.2451 c 0 2.9646 0.7533 4.5927 3.1833 6.561 c 3.0618 2.7459 5.4675 2.8431 10.7163 0.7533 c 1.7496 -0.6561 1.9683 -0.3402 1.6524 1.8711 c -0.3402 2.4057 -0.972 2.6244 -6.561 2.9646 c -5.5647 0.3402 -6.561 0 -9.6145 -1.6641 z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
